import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { filter } from 'rxjs/operators';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { XpoLtlLoggedInUserService } from './shared/services/logged-in-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;

  apiStoreRoute = `/${AppRoutes.API_STORE}`;

  constructor(
    private configManagerService: ConfigManagerService,
    private router: Router,
    private loggedInUserService: XpoLtlLoggedInUserService,
    private xpoLtlAuthService: XpoLtlAuthenticationService
  ) {
    /** Shell setup */
    this.title = 'API Store';
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  ngOnInit(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);

    this.xpoLtlAuthService.initAuthSetup$(region).subscribe((regionInfo) => {
      this.loggedInUserService.initLoggedInUser();
      this.loggedInUserService.loggedInUser$.pipe(filter((user) => !!user)).subscribe();
    });
  }
}
