import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.API_STORE,
    data: { breadcrumb: 'API Store' },
    loadChildren: () => import('./api-store/api-store.module').then((m) => m.ApiStoreModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: AppRoutes.API_STORE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routerDefinitions, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
