import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    XpoButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    XpoCardModule,
    XpoStatusIndicatorModule,
    MatExpansionModule,
    MatInputModule,
    MatMenuModule,
    XpoDialogModule,
    XpoIconModule,
    MatRadioModule,
    MatChipsModule,
    // Pipes/Directives
  ],
  exports: [BreadcrumbsComponent],
})
export class SharedModule {}
