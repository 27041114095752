<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button [routerLink]="apiStoreRoute" routerLinkActive="xpo-selected">
      LTL APIs
    </button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="content">
    <div class="app-Container-pre-content">
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <router-outlet></router-outlet>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<xpo-app-notification></xpo-app-notification>
